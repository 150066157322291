<template>
  <div class="big-box">
    <h3 class="warning">
      <i class="el-icon-s-promotion"></i>
      所有图片必须压缩再上传
      <el-link style="font-size: 18px" @click="get_jecoco_report('https://tinypng.com/')">
        <u>点击跳转至图片压缩网址</u>
      </el-link>
    </h3>
    <el-form label-position="left" :inline="true" class="demo-form-inline">
      <el-form-item label="资源名称：">
        <el-input v-model="photoName" placeholder="请输入资源名称"></el-input>
      </el-form-item>
      <el-form-item label="类型:">
        <el-select v-model="imgMediaType" placeholder="--请选择--">
          <el-option label="图片" value="1"></el-option>
          <el-option label="视频" value="2"></el-option>
          <el-option label="全部" value=""></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="imgStatus" placeholder="全部">
          <el-option label="生效" value="1"></el-option>
          <el-option label="失效" value="2"></el-option>
          <el-option label="全部" value=""></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search('clear')">查询</el-button>
        <!-- <el-button type="primary" @click="clearModuleCode">重置</el-button> -->
      </el-form-item>
    </el-form>
    <div class="head-box">
      <el-button type="primary" size="medium" @click="newAdd" v-if="$has('add')">新增</el-button>
      <el-button type="danger" size="medium" @click="loseEfficacy" v-if="$has('change')">失效</el-button>
      <el-button type="success" size="medium" @click="takeEffect" v-if="$has('change')">生效</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
          style="width: 90%; margin-left: 40px">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="file_nickname" align="center" label="资源名称" min-width="150">
          </el-table-column>
          <el-table-column prop="media_type" align="center" label="类型" min-width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.media_type == 1 ? '图片' : '视频' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="image_url" align="center" min-width="180" label="缩略图">
            <template slot-scope="scope">
              <img v-if="scope.row.media_type == 1" style="max-width: 100px;max-height: 100px"
                @click="checkPhoto(scope.row.image_url)" :src="scope.row.image_url" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="id" align="center" min-width="80" label="媒体ID">
          </el-table-column>
          <el-table-column prop="size" align="center" min-width="100" label="图片大小">
          </el-table-column>
          <el-table-column prop="created_at" align="center" min-width="150" label="上传时间">
          </el-table-column>
          <!-- <el-table-column prop="op_user_name" align="center" min-width="120" label="操作人">
          </el-table-column> -->
          <el-table-column prop="status_cn" align="center" min-width="120" label="状态">
            <template slot-scope="scope">
              <span :style="{ color: statusColor(scope.row.status) }">{{
                  scope.row.status == 1 ? '生效' : '失效'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" align="center" min-width="180" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="checkImg(scope.row)">查看</el-button>
              <el-button type="primary" size="small" @click="copyUrl(scope.row)">复制媒体ID</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page"
          :page-size="10" layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </div>
    <el-dialog :visible.sync="dialogPvVisible" width="600px" title="新增">
      <el-form label-width="150px" :model="createModule">
        <el-form-item required label="资源名称:">
          <el-input placeholder="请输入资源名称" v-model="createModule.name" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item required label="类型:">
          <el-select v-model="createModule.type" placeholder="--请选择--">
            <el-option label="图片" value="1"></el-option>
            <el-option label="视频" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="选择文件:">
          <el-upload class="upload-demo" ref="upload" action="/api/admin/heat_curve/del" :on-preview="handlePreview"
            :on-remove="handleRemove" :on-change="handleChange" :limit="1" :file-list="fileList" :auto-upload="false">
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPvVisible = false">取消</el-button>
        <el-button type="primary" @click="submitadd">确认上传</el-button>
      </span>
    </el-dialog>
    <CheckPhoto ref="CheckPhoto" :imgUrl="imgUrl"></CheckPhoto>
  </div>
</template>
<script>
import { mediaSearch, mediaUpload, mediaEditStatus } from "@/api/data.js";
import CheckPhoto from "@/components/CheckPhoto.vue";
export default {
  components: { CheckPhoto },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      photoName: '',
      imgMediaType: '',
      imgStatus: '',
      fileList: [],
      file: "",
      imgUrl: '',
      centerDialogVisible: false,
      imgList: [
        {
          img: "",
          link: "",
        },
        {
          img: "",
          link: "",
        },
      ],
      dialogPvVisible: false,
      multipleSelection: [],
      createModule: {
        name: ''
      },
      tableData: [],
      roles: ['admin', 'user'],
    };
  },
  created() {
  },
  watch: {},
  mounted() {
    this.search('clear')
  },
  methods: {
    get_jecoco_report (url) {
      window.open(url)
    },
    checkPhoto(item) {
      this.imgUrl = item
      this.imgId = ''
      this.$refs.CheckPhoto.open();
    },
    copyUrl(item) {
      let transfer = document.createElement("input"); //创建控件
      document.body.appendChild(transfer);
      transfer.style.cssText = "position: absolute;right: 45%;top: 80%;";
      transfer.value = item.id; // 这里表示想要复制的内容
      transfer.focus();
      transfer.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      transfer.blur();
      // console.log('复制成功');
      document.body.removeChild(transfer); //删除控件
      this.$message.success("复制成功");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    newAdd() {
      this.dialogPvVisible = true;
      this.createModule = {
        name: ''
      };
      this.fileList = []
      this.file = ''
    },
    delData() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
    },
    loseEfficacy() {  // 失效
      this.edit(2)
    },

    takeEffect() { // 生效
      this.edit(1)
    },
    edit(type) {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择");
        return;
      }
      if (type === 1) {
        const isAllOff = this.multipleSelection.every(function (item, index, arr) {
          return item.status === 2;
        });
        if (!isAllOff) {
          this.$message.error("数据需全为失效状态");
          return
        }
      }
      if (type === 2) {
        const isAllOn = this.multipleSelection.every(function (item, index, arr) {
          return item.status === 1;
        });
        if (!isAllOn) {
          this.$message.error("数据需全为生效状态");
          return
        }
      }
      let arr = []
      arr = this.multipleSelection.map(function (item, index, arr) {
        return item.id
      })
      mediaEditStatus({
        media_id: arr,
        status: type
      }).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success('状态修改成功');
          this.multipleSelection = []
          this.search('clear')
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkImg(item) {
      if (item.media_type != 1) {
        window.open(item.image_url)
      } else {
        this.imgUrl = item.image_url
        this.$refs.CheckPhoto.open();
      }
    },
    handleRemove(file, fileList) {
      console.log('handleRemove', file, fileList);
    },
    handlePreview(file) {
      console.log('handlePreview', file);
    },
    handleChange(file) {
      this.file = file.raw;
    },
    beforeUpload() {
      let file = this.file;
      if (!file) {
        this.$message.error('请选择文件');
        return
      }
      var FileExt = file.name.replace(/.+\./, "")
      const isLt5M = file.size / 1024 / 1024 < 100
      var extension = ['exe', 'iso'].indexOf(FileExt.toLowerCase()) === -1
      // if (!extension) {
      //   this.$message({
      //     type: 'warning',
      //     message: '禁止上传 exe, iso 文件！'
      //   })
      //   return false
      // }
      if (!isLt5M) {
        this.$message({
          type: 'warning',
          message: '文件不能超过 100M'
        })
        return false
      }
      return true
    },
    submitadd() {
      if (!this.createModule.type) {
        this.$message.error('请选择图片类型');
        return
      }
      if (!this.createModule.name.trim()) {
        this.$message.error('请输入资源名称');
        return
      }
      if (!this.beforeUpload()) {
        return
      }
      // this.dialogPvVisible = false;
      // // this.$refs.upload.submit();
      const param = new FormData(); //提交formData
      let files = this.file;
      param.append("file_name", files);
      param.append("media_type", this.createModule.type); // 资源类型1=图片，2=视频
      param.append("file_nickname", this.createModule.name);
      param.append("platform", 1);

      mediaUpload(param).then(({ data: res }) => {
        if (res.code === 200) {
          this.$message.success("上传成功");
          this.fileList = [];
          this.dialogPvVisible = false
          this.search('clear');
          this.createModule = {
            name: ''
          };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    search(type) {
      if (type == 'clear') {
        this.page = 1
      }
      mediaSearch({
        status: this.imgStatus,
        file_nickname: this.photoName,
        media_type: this.imgMediaType,
        page: this.page,
        limit: this.limit
      }).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$message.error(res.message);
        }
      });
    },
    statusColor(status) {
      if (status == 1) {
        return "green";
      } else if (status == 2) {
        return "red";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.demo-form-inline {
  margin-top: 22px;
  padding-left: 40px;
}

.head-box {
  height: 40px;
  margin: 20px 0 10px 40px;
}

.add-image {
  text-align: center;
  color: #3ea8d6;
}

.demo-form-inline {
  margin-top: 22px;
  padding-left: 50px;
}

.input-box {
  width: 120px;
  margin-right: 10px;
}

.warning {
  color: red;
  margin-left: 40px;
}

::v-deep .el-input {
  width: 260px;
}

::v-deep .el-pagination {
  margin-top: 10px;
  margin-left: 40px;
}
</style>
